<template>
  <div class="whole">
    <el-card class="card">
      <!-- Upload commodity master map -->
      <p class="p" style="margin-top: 0">*{{ $t("productManagement.uploadText") }}</p>
      <el-row :gutter="165" class="mt1">
        <el-col>
          <div class="gallery_list" v-for="(item, index) in gallery" :key="index">
            <i class="el-dialog__close el-icon el-icon-close" @click="deleteImage(index, 1)"></i>
            <i
              class="el-icon-caret-left el-left"
              @click="left(index, 1)"
              v-show="index != 0 ? true : false"
            ></i>
            <i
              class="el-icon-caret-right el-right"
              @click="right(index, 1)"
              v-show="index < gallery.length - 1 ? true : false"
            ></i>
            <el-image
              :src="item.img + '?x-oss-process=style/w128'"
              style="width: 100px; height: 100px"
              @click="openImageViewer(index, 1)"
            ></el-image>
          </div>
          <MaterialSelector :count="20" type="image" @receiver="handleSelectionResult($event, 1)">
            <template v-slot:custom-open-button>
              <div class="gallery_list plus">
                <i class="el-icon-plus plus-icon"></i>
              </div>
            </template>
          </MaterialSelector>
          <!-- <div
            class="gallery_list"
            :class="hiddenUploadGallery"
            @click="clickUploadImages(1)"
          >
            <i class="el-icon-plus uploadBtn"></i>
          </div>
          <input
            type="file"
            id="uploadGalleryImages"
            style="display: none"
            accept="image/gif,image/jpeg,image/png,image/jpg"
            @change="uploadImages"
          />-->

          <!-- <el-upload
            class="upload-oss"
            :class="hiddenUploadGallery"
            drag
            action
            :data="{ type: 1 }"
            :http-request="handleUpload"
            multiple
            :show-file-list="false"
            accept=".jpg,.jpeg,.png"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              {{ $t("productManagement.uploadTips")
              }}<em>{{ $t("productManagement.uploadClick") }}</em>
            </div>
          </el-upload>-->
        </el-col>
      </el-row>
      <p class="explain">{{ $t("productManagement.uploadImageSize") }}</p>

      <!-- Upload Upload product video -->
      <p class="p">{{ $t("productManagement.uploadVideoText") }}</p>
      <el-row :gutter="300" class="mt1">
        <el-col>
          <div v-if="video" class="upload-video-wrap">
            <video :src="video" class="video-display" controls />
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="handleVideoDelete"
            >{{ $t("common.delete") }}</el-button>
          </div>
          <MaterialSelector
            v-else
            :count="1"
            type="video"
            @receiver="handleSelectionResult($event, 3)"
          >
            <template v-slot:custom-open-button>
              <div class="gallery_list plus">
                <i class="el-icon-plus plus-icon"></i>
              </div>
            </template>
          </MaterialSelector>
        </el-col>
      </el-row>
      <p class="explain">{{ $t("productManagement.uploadvideoSize") }}</p>

      <p class="p">*{{ $t("productManagement.productDetailImages") }}</p>
      <el-row :gutter="165" class="mt1">
        <el-col>
          <div class="gallery_list" v-for="(item, index) in detailImages" :key="index">
            <i class="el-dialog__close el-icon el-icon-close" @click="deleteImage(index, 2)"></i>
            <i
              class="el-icon-caret-left el-left"
              @click="left(index, 2)"
              v-show="index != 0 ? true : false"
            ></i>
            <i
              class="el-icon-caret-right el-right"
              @click="right(index, 2)"
              v-show="index < detailImages.length - 1 ? true : false"
            ></i>
            <el-image
              :src="item.img + '?x-oss-process=style/w128'"
              style="width: 100px; height: 100px"
              @click="openImageViewer(index, 2)"
            ></el-image>
          </div>
          <MaterialSelector :count="20" type="image" @receiver="handleSelectionResult($event, 2)">
            <template v-slot:custom-open-button>
              <div class="gallery_list plus">
                <i class="el-icon-plus plus-icon"></i>
              </div>
            </template>
          </MaterialSelector>
          <!-- <div class="gallery_list" @click="clickUploadImages(2)">
            <i class="el-icon-plus uploadBtn"></i>
          </div>
          <input
            type="file"
            id="uploadDetailImages"
            style="display: none"
            accept="image/gif,image/jpeg,image/png,image/jpg"
            @change="uploadImages"
          />-->

          <!-- <el-upload
            class="upload-oss"
            drag
            action
            :data="{ type: 2 }"
            :http-request="handleUpload"
            multiple
            :show-file-list="false"
            accept=".jpg,.jpeg,.png"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              {{ $t("productManagement.uploadTips")
              }}<em>{{ $t("productManagement.uploadClick") }}</em>
            </div>
          </el-upload>-->
        </el-col>
      </el-row>
      <p class="explain">{{ $t("productManagement.uploadImageSize") }}</p>
    </el-card>

    <el-image-viewer
      :initial-index="imageViewIndex"
      v-if="showImageViewer"
      :url-list="imageList"
      :on-close="onCloseImageViewer"
    />

    <div class="bottom1">
      <el-button class="button1" @click="previousStep">
        {{
        $t("productManagement.previousStep")
        }}
      </el-button>
      <el-button class="button1" @click="goBack">
        {{
        $t("productManagement.backToList")
        }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { getData, createData } from "@/helper/api.js";
// import { renderData,deleteData,createData} from "../../helper/api";
// import { getFileNameMd5, put } from "@/helper/ali-oss";
// import Img from "@/components/UploadImage";
import MaterialSelector from "@/components/MaterialSelector";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    MaterialSelector,
    ElImageViewer
  },
  data() {
    return {
      productId: 0,
      gallery: [],
      image: "",
      upload_image: "",
      hiddenUploadGallery: "",
      detailImages: [],
      msg: "picture",
      storeId: "",
      // galleryList: [],
      // detailImagesList: [],
      video: "",

      showImageViewer: false,
      imageViewType: 0,
      imageViewIndex: 0
    };
  },
  created() {
    this.productId = this.$route.query.product_id;
    this.storeId = localStorage.getItem("storeId");
    this.getProduct();
    // this.oss();
    // this.ossLIst();
  },
  computed: {
    imageList: function() {
      if (this.imageViewType === 0) {
        return [];
      } else {
        return (this.imageViewType === 1
          ? this.gallery
          : this.detailImages
        ).map(v => {
          return v.img;
        });
      }
    }
  },
  methods: {
    // 删除视频
    handleVideoDelete() {
      this.video = "";
    },
    onCloseImageViewer() {
      this.showImageViewer = false;
    },
    openImageViewer(index, type) {
      this.imageViewIndex = index;
      this.imageViewType = type;
      setTimeout(() => {
        this.showImageViewer = true;
      });
    },
    handleSelectionResult(result, type) {
      console.log("handleSelectionResult:", result, type);
      if (type === 1) {
        // 主图
        const gallery = result.map((v, i) => {
          return {
            img: v.filename,
            sortingOrder: i,
            isPrimary: false
          };
        });
        this.gallery = [...this.gallery, ...gallery];
      } else if (type === 2) {
        // 详情图
        const detailImages = result.map((v, i) => {
          return {
            img: v.filename,
            sortingOrder: i
          };
        });
        this.detailImages = [...this.detailImages, ...detailImages];
      } else if (type === 3) {
        if (
          result.Transcode &&
          result.Transcode.StreamInfos &&
          result.Transcode.StreamInfos.length > 0
        ) {
          let StreamInfo = result.Transcode.StreamInfos.find(
            v => v.Definition === "FD"
          );
          if (!StreamInfo) {
            StreamInfo = result.Transcode.StreamInfos[0];
          }
          this.video = StreamInfo.FileUrl;
        } else {
          console.log("handleSelectionResult-videoerror-result:", result);
          this.$message({
            message: this.$t("productManagement.chooseVideoError"),
            type: this.$t("common.warning")
          });
        }
      }
    },
    // 上传图片组件事件
    uploadComponent(payload, data) {
      // console.log("payload", payload, data);
      // console.log("...this.gallery, ...payload",...this.gallery, ...payload);
      // console.log("payload", payload);

      // 主图
      const payloadFormat = payload.map((v, i) => {
        return {
          img: v.filename,
          sortingOrder: i,
          isPrimary: false
        };
      });

      // console.log("payloadFormat", payloadFormat);
      if (data == 1) {
        this.gallery = [...this.gallery, ...payloadFormat];
        // console.log("this.gallery", this.gallery, data);
      }

      // 详情图
      const payloadFormats = payload.map((v, i) => {
        return {
          img: v.filename,
          sortingOrder: i
        };
      });

      // console.log("payloadFormats", payloadFormats);
      if (data == 2) {
        this.detailImages = [...this.detailImages, ...payloadFormats];
        // console.log("this.detailImages", this.detailImages, data);
      }
      // this.getProduct()
      // this.msg = payload;
    },
    // 删除
    deleteImage(index, type) {
      // console.log("deleteImage-index:", index, "type:", type);
      if (type === 1) {
        // console.log("this.gallery", this.gallery);
        this.gallery.splice(index, 1);
      }
      if (type === 2) {
        // console.log("this.detailImages", this.detailImages);
        this.detailImages.splice(index, 1);
      }
    },
    // 图片获取
    getProduct() {
      getData("GET", "product", this.productId).then(res => {
        this.gallery = res.data.gallery;
        this.detailImages = res.data.detailDawing;
        this.video = res.data.video;
      });
    },
    // 左
    left(index, type) {
      // console.log(" this.gallery:", this.gallery);
      if (type === 1) {
        this.gallery.splice(
          index - 1,
          1,
          ...this.gallery.splice(index, 1, this.gallery[index - 1])
        );
      }
      if (type === 2) {
        this.detailImages.splice(
          index - 1,
          1,
          ...this.detailImages.splice(index, 1, this.detailImages[index - 1])
        );
      }
    },
    // 右
    right(index, type) {
      // console.log(" this.detailImages:", this.detailImages);
      if (type === 1 && index < this.gallery.length) {
        this.gallery.splice(
          index + 1,
          1,
          ...this.gallery.splice(index, 1, this.gallery[index + 1])
        );
      }
      if (type === 2) {
        this.detailImages.splice(
          index + 1,
          1,
          ...this.detailImages.splice(index, 1, this.detailImages[index + 1])
        );
      }
    },

    // 上一步
    previousStep() {
      this.$router.push(
        "productEdit?product_id=" + this.$route.query.product_id
      );
    },

    // 保存返回列表
    goBack() {
      createData("PUT", "product/imgs", {
        id: this.productId,
        gallery: this.gallery,
        detailDawing: this.detailImages,
        video: this.video
      }).then(res => {
        // console.log("goBack:", res);
        if (res.code == 200) {
          this.$message({
            message: this.$t("common.updateSuccess"),
            type: "success"
          });
          this.$router.push("products");
        } else {
          this.$message({
            message: res.message,
            type: "error"
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.whole {
  position: relative;
  height: 100%;
}
.p {
  font-size: 15px;
  margin-top: 40px;
}
.card {
  min-height: 538px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #cec9c9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.up /deep/ .el-upload--picture-card {
  width: 100px;
  height: 71px;
  position: relative;
}
.up /deep/ .el-upload-list__item-thumbnail {
  width: 100px;
  height: 71px;
}
.up /deep/ .el-upload-list__item {
  width: 100px;
  height: 71px;
  margin-right: 32px;
}
.up /deep/ .el-icon-plus {
  position: absolute;
  font-size: 20px;
  top: 30px;
  left: 40px;
}
.explain {
  margin-top: 10px;
  font-size: 10px;
  color: gray;
}

.bottom1 {
  width: 100%;
  height: 80px;
  bottom: 0;
  text-align: center;
  background: #fff;
  margin-top: 20px;
  border-radius: 10px;
}
.bottom1 .button1 {
  min-width: 131px;
  height: 100%;
  margin-right: 41px;
  background: #fae1e0;
  color: #ff0a0a;
  font-size: 15px;
  font-weight: 500;
  border: none;
}
.bottom1 .button2 {
  width: 131px;
  height: 100%;
  background: #ff0a0a;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border: none;
}
.gallery_list {
  width: 100px;
  height: 70px;
  float: left;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  vertical-align: middle;
  margin-right: 20px;
  margin-bottom: 5px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}
.gallery_list.plus {
  display: flex;
  justify-content: center;
  align-items: center;
}
.plus-icon {
  color: #999;
  font-size: 30px;
}
.gallery_list .el-icon-close {
  position: absolute;
  border: 1px red solid;
  margin-left: 87px;
  margin-top: -5px;
  background-color: red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  color: #ffffff;
  z-index: 100;
}
.gallery_list .el-left {
  position: absolute;
  margin-left: -10px;
  border: 1px solid gray;
  border-radius: 10px;
  margin-top: 30px;
  color: #000;
  z-index: 100;
}
.gallery_list .el-right {
  position: absolute;
  margin-left: 90px;
  border: 1px solid gray;
  border-radius: 10px;
  margin-top: 30px;
  color: #000;
  z-index: 100;
}
.gallery_list img {
  width: 100px;
  height: 70px;
}
.gallery_list .uploadBtn {
  vertical-align: middle;
  padding-top: 25px;
}
.hidden_gallery_upload,
.hidden_detail_upload {
  display: none;
}
.upload-oss {
  margin-top: 20px;
  clear: both;
  display: block;
}
.video-display {
  width: 640px;
  height: 360px;
}
</style>
